<template>
    <div>
        <div class="screen_title">Pending Tasks</div>
        <div class="screen_sub_title">Complete any task and receive {{ tokenName }}!</div>
        <div class="task_block">
            <div class="task_item" v-for="task in tasks" :key="task">
                <div class="task_item_left">
                    <div class="task_title">
                        {{ task.title }}
                    </div>
                    <div class="task_bonus">
                        +{{ numberWithCommas(task.bonus) }} {{ tokenName }}
                    </div>
                </div>
                <div class="task_item_right" v-if="!task.status">
                    <button @click="onClickToStart(task.link, task.taskId)">Start</button>
                </div>
                <div class="task_item_right" v-if="task.status == 1">
                    <button>Pending</button>
                </div>
                <div class="task_item_right" v-if="task.status == 2">
                    <button @click="claimTask(task.taskId, task.bonus)">Claim</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";

export default {
    /* eslint-disable */
    name: "task",
    data() {
        return {
            tasks: [],
            tokenName: ""
        }
    },
    methods: {
        getTaskPending() {
            http.get('/task/in-complete', { params: {
                accountId: localStorage.getItem("user_account")
            } }).then(res => {
                if(res.data.status === 200) {
                    this.tasks = res.data.data;
                }
            }); 
        },
        getUserInfo() {
            http.get('/user/infor', { params: {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            } }).then(res => {
                if(res.data.status === 200) {
                    this.tokenName = res.data.tokenName;
                }
            });
        },
        pushStart(link, taskId) {
            http.post('/task/start', {
                accountId: localStorage.getItem("user_account"),
                taskId: taskId,
                game: localStorage.getItem("game")
            }).then(() => {
                const accountId = localStorage.getItem("user_account");
                window.location.href = link + "?accountId=" + accountId;
            });
        },
        claimTask(taskId, bonus) {
            http.post('/task/claim', {
                accountId: localStorage.getItem("user_account"),
                taskId: taskId,
                game: localStorage.getItem("game")
            }).then(res => {
                if(res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "+" + this.numberWithCommas(bonus) + ' CATCH',
                        type: "success"
                    });

                    this.tasks = this.tasks.filter(function( obj ) {
                        return obj.taskId !== taskId;
                    });
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onClickToStart(link, taskId) {
            this.pushStart(link, taskId);
        }
    },
    mounted() {
        this.getTaskPending();
        this.getUserInfo();
    }
}
</script>