<template>
    <div>
        <div class="screen_title">Wallet</div>
        <div class="screen_sub_title">
            <div id="connect-wallet"></div>
            <div>Connect your wallet to receive {{ tokenName }}!</div>
        </div>
    </div>
</template>
<script>
import { TonConnectUI } from '@tonconnect/ui';
import http from "@/http";
export default {
    /* eslint-disable */
    name: 'Wallet',
    data() {
        return {
            tokenName: ""
        }
    },
    methods: {
        async connectWallet(address) {
            return http.post("/transaction/connect", {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game"),
                address: address
            }, {}).then(res => {
                if (res.data.status === 200) {
                    console.log(res);
                    this.$notify({
                        title: "MESSAGE",
                        text: "Connected!",
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        },
        getUserInfo() {
            http.get("/user/infor", { params: {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            }}).then(res => {
                this.tokenName = res.data.tokenName;
            });
        },
    },
    mounted() {
        const tonConnectUI = new TonConnectUI({
            manifestUrl: "https://bot.cathunter.xyz/tonconnect-manifest.json",
            buttonRootId: "connect-wallet"
        });

        tonConnectUI.onStatusChange(walletAndwalletInfo => {
            if (walletAndwalletInfo && walletAndwalletInfo.account) {
                let address = walletAndwalletInfo.account.address;
                this.connectWallet(address);
            }
        });
    }
}
</script>