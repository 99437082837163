<template>
    <div>
        <div class="loader-bar">
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-border"></div>
            <div class="block-meter" v-for="current in process()" :key="current"></div>
        </div>
        <div class="energy_text">
            Energy ({{ energy }} / {{ totalEnergy }})
        </div>
        <div v-if="leftTime > 0"><bolt-icon class="icon small_icon"/> Charing: {{ leftTime }} (seconds)</div>
        <div v-else><battery-100-icon class="icon small_icon"/> FULL</div>
        <div class="main_point" id="target-element">
            <count-up :end-val="endVal" :start-val="startVal"></count-up>
            <span>{{ tokenName }}</span>
        </div>
        <div class="main_image" @click="catchCat()" v-if="imageUrl">
             <img :src="imageUrl" class="main_image_cat"/>
        </div>
    </div>
</template>
<script>
import CountUp from 'vue-countup-v3';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { BoltIcon, Battery100Icon } from '@heroicons/vue/24/solid';
import http from "@/http";
export default {
    /* eslint-disable */
    name: "home",
    components: {
        CountUp,
        LottieAnimation,
        BoltIcon,
        Battery100Icon
    },
    data() {
        return {
            energy: 0,
            totalEnergy: 0,
            endVal: 0,
            startVal: 0,
            user: null,
            leftTime: 0,
            countDownThread: null,
            timePerform: 0,
            rounting: 0,
            imageUrl: "",
            tokenName: "",
            primaryColor: ""
        }
    },
    methods: {
        async catchCat() {
            if(this.energy > 0) {
                this.energy--;
                this.clickAnimation();
                let numbers = await this.createTransaction();
                let newVal = numbers + this.endVal;
                
                this.startVal = this.endVal;
                this.endVal = newVal;
            }
        },
        process() {
            if(this.totalEnergy === 0) return 0;
            return Math.ceil(((this.energy / this.totalEnergy) * 100) / 10);
        },
        clickAnimation() {
            const coin = document.createElement('div');
            coin.classList.add('coin');
            coin.style.backgroundColor = this.primaryColor;
            coin.style.left = `${event.clientX}px`;
            coin.style.top = `${event.clientY}px`;

            document.body.appendChild(coin);

            const target = document.getElementById('target-element');
            const targetRect = target.getBoundingClientRect();
            const targetX = targetRect.left + (targetRect.width / 2) - event.clientX + 'px';
            const targetY = targetRect.top + (targetRect.height / 2) - event.clientY + 'px';

            coin.style.setProperty('--targetX', targetX);
            coin.style.setProperty('--targetY', targetY);

            coin.addEventListener('animationend', () => {
                coin.remove();
            });
        },
        getUserInfor() {
            http.get("/user/infor", { params: {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            }}).then(res => {
                if(res.data.status === 200) {
                    this.user = res.data.user;
                    this.leftTime = res.data.leftTime;
                    this.timePerform = res.data.timePerform;
                    this.energy = this.user.ticket;
                    this.totalEnergy = this.user.energy;
                    this.endVal = this.user.balance;
                    this.imageUrl = res.data.imageUrl;
                    this.tokenName = res.data.tokenName;
                    this.primaryColor = res.data.primaryColor;
                    console.log(this.primaryColor);
                }
            });
        },
        async createTransaction() {
            return http.post("/transaction/create", {
                type: 'bal',
                number: 1,
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            }, {}).then(res => {
                if(res.data.status === 200) {
                    return res.data.number;
                } else {
                    return 0;
                }
            });
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const userAccount = urlParams.get('user_account');
        const game = urlParams.get('game');

        if(userAccount) {
            localStorage.setItem("user_account", userAccount);
        }
        
        if(game) {
            localStorage.setItem("game", game);
        }

        this.getUserInfor();

        if(this.countDownThread) {
            clearInterval(this.countDownThread);
        }

        this.countDownThread = setInterval(() => {
            if(this.rounting == this.timePerform) {
                this.energy++;
                this.rounting = 0;
            }

            this.rounting++;
            this.leftTime --;
        }, 1000);
    }
}
</script>